import $ from 'jquery';
window.$ = $;
window.jQuery = $;

$(document).ready(function() {
    console.log("jQuery is working!");
});

import './bootstrap.js';


